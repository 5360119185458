<template>
  <div>{{ block.text }}</div>
</template>

<script>
export default {
  components: {},
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    //
  },
};
</script>
